const navigation = [
	{
		title: 'Gestionar Pacientes',
		icon: 'ShieldIcon',
		children: [
			{
				title: 'Dashboard',
				route: 'patientdashboard',
			},
			{
				title: 'Pacientes',
				route: 'patients',
			},
			{
				title: 'Riesgo paciente',
				route: 'patientsrisks',
			},
			{
				title: 'Especialidad',
				route: 'patientspecialties',
			},
			{
				title: 'Entidad',
				route: 'patiententities',
			},
			{
				title: 'Exámenes',
				route: 'patientexams',
			},
			// {
			// 	title: 'Medicamentos',
			// 	route: 'patientmedicines',
			// },
		],
	},
]

const routes = [
	{
		path: '/patient',
		// name: '',
		component: () =>
			import(
				/* webpackChunkName: "prmain" */
				'@/modules/patient/Main.vue'
			),
		children: [
			{
				path: 'dashboard',
				name: 'patientdashboard',
				component: () =>
					import(
						/* webpackChunkName: "patientdashboard" */
						'@/modules/patient/Dashboard.vue'
					),
			},
			{
				path: '/paciente',
				name: 'patients',
				component: () =>
					import(
						/* webpackChunkName: "patients" */
						'@/modules/patient/Patients/Patients.vue'
					),
				meta: {
					pageTitle: 'Pacientes',
					breadcrumb: [
						{
							text: 'Dashboard',
							to: { name: 'patientdashboard' },
						},
						{
							text: 'Pacientes',
							active: true,
						},
					],
				},
			},
			{
				path: '/riesgos',
				name: 'patientsrisks',
				component: () =>
					import(
						/* webpackChunkName: "patientsrisks" */
						'@/modules/patient/Risks/Risks.vue'
					),
				meta: {
					pageTitle: 'Riesgo paciente',
					breadcrumb: [
						{
							text: 'Dashboard',
							to: { name: 'patientdashboard' },
						},
						{
							text: 'Riesgo paciente',
							active: true,
						},
					],
				},
			},
			{
				path: 'medicines',
				name: 'patientmedicines',
				component: () =>
					import(
						/* webpackChunkName: "patientmedicines" */
						'@/modules/patient/Medicine/Medicines.vue'
					),
				meta: {
					pageTitle: 'Medicamentos',
					breadcrumb: [
						{
							text: 'Dashboard',
							to: { name: 'patientdashboard' },
						},
						{
							text: 'Medicamentos',
							active: true,
						},
					],
				},
			},
			{
				path: 'exams',
				name: 'patientexams',
				component: () =>
					import(
						/* webpackChunkName: "patientexams" */
						'@/modules/patient/Exams/Exams.vue'
					),
				meta: {
					pageTitle: 'Exámenes',
					breadcrumb: [
						{
							text: 'Dashboard',
							to: { name: 'patientdashboard' },
						},
						{
							text: 'Exámenes',
							active: true,
						},
					],
				},
			},
			{
				path: 'entities',
				name: 'patiententities',
				component: () =>
					import(
						/* webpackChunkName: "patiententities" */
						'@/modules/patient/Entity/Entity.vue'
					),
				meta: {
					pageTitle: 'Entidades',
					breadcrumb: [
						{
							text: 'Dashboard',
							to: { name: 'patientdashboard' },
						},
						{
							text: 'Entidades',
							active: true,
						},
					],
				},
			},
			{
				path: 'specialties',
				name: 'patientspecialties',
				component: () =>
					import(
						/* webpackChunkName: "patientspecialties" */
						'@/modules/patient/Specialty/Specialty.vue'
					),
				meta: {
					pageTitle: 'Especialidades',
					breadcrumb: [
						{
							text: 'Dashboard',
							to: { name: 'patientdashboard' },
						},
						{
							text: 'Especialidades',
							active: true,
						},
					],
				},
			},
			{
				path: 'historial-paciente',
				name: 'patientHistory',
				component: () =>
					import(
						/* webpackChunkName: "patientHistory" */
						'@/modules/patient/patient-history/PatientHistory.vue'
					),
				meta: {
					pageTitle: 'Historial paciente',
				},
			},
			/**
			 * 	MODULO DE MEDICAMENTOS
			 */
			{
				path: '/paciente/medicamentos',
				name: 'medicamentos-paciente',
				component: () =>
					import(
						/* webpackChunkName: "medicamentos-paciente" */
						'@/modules/patient/Medicine/Medicines-Patient.vue'
					),
				meta: {
					pageTitle: 'Farmacia',
					breadcrumb: [
						{
							text: 'Pacientes',
						},
						{
							text: 'Medicamentos',
							active: true,
						},
					],
				},
			},
			{
				path: '/paciente/farmacia',
				name: 'farmacia',
				component: () =>
					import(
						/* webpackChunkName: "farmacia" */
						'@/modules/patient/pharmacy/Pharmacy.vue'
					),
				meta: {
					pageTitle: 'Farmacia',
					breadcrumb: [
						{
							text: 'Pacientes',
						},
						{
							text: 'farmacia',
							active: true,
						},
					],
				},
			},
			{
				path: '/ingreso-paciente/:locationId',
				name: 'find-paciente',
				component: () =>
					import(
						/* webpackChunkName: "find-paciente" */
						'@/modules/patient/find-patient/Find-Patient'
					),
				meta: {
					pageTitle: 'Buscar paciente',
					breadcrumb: [
						{
							text: 'Dashboard',
							to: { name: 'patientdashboard' },
						},
						{
							text: 'Buscar paciente',
							active: true,
						},
					],
				},
			},
			{
				path: '/register-paciente/:locationId/:patientId',
				name: 'register-paciente',
				component: () =>
					import(
						/* webpackChunkName: "register-paciente" */
						'@/modules/patient/register-patient/Register-Patient'
					),
				meta: {
					pageTitle: 'Registro de paciente',
					breadcrumb: [
						{
							text: 'Dashboard',
							to: { name: 'patientdashboard' },
						},
						{
							text: 'Registro de paciente',
							active: true,
						},
					],
				},
			},
			{
				path: '/paciente/registrar-cama/:locationId/:patientId',
				name: 'register-cama-paciente',
				component: () =>
					import(
						/* webpackChunkName: "registerbed" */
						'@/modules/patient/register-bed/Register-Bed.vue'
					),
				meta: {
					pageTitle: 'Registrar Cama Paciente',
					breadcrumb: [
						{
							text: 'Dashboard',
							to: { name: 'patientdashboard' },
						},
						{
							text: 'Registrar Cama - Paciente',
							active: true,
						},
					],
				},
			},
		],
	},
]

export { navigation, routes }
